// Generated by Framer (1d71865)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {rhq3ewyDU: {hover: true}};

const cycleOrder = ["rhq3ewyDU"];

const serializationHash = "framer-O15fl"

const variantClassNames = {rhq3ewyDU: "framer-v-f4yiok"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, link, width, ...props}) => { return {...props, bSr_pnF2L: link ?? props.bSr_pnF2L, NRJc423C6: image ?? props.NRJc423C6} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string};link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, NRJc423C6, bSr_pnF2L, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "rhq3ewyDU", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={bSr_pnF2L}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-f4yiok", className, classNames)} framer-1jl54gs`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"rhq3ewyDU"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-60b8256d-8ac8-433f-b6b8-cb9776d3a855, rgb(39, 43, 39))", ...style}} {...addPropertyOverrides({"rhq3ewyDU-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(NRJc423C6), ...{ positionX: "center", positionY: "center" }}} className={"framer-8yq1bh"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"T8P2XkmTO"} style={{opacity: 1, scale: 1}} variants={{"rhq3ewyDU-hover": {opacity: 0.8, scale: 1.1}}}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-O15fl.framer-1jl54gs, .framer-O15fl .framer-1jl54gs { display: block; }", ".framer-O15fl.framer-f4yiok { cursor: pointer; height: 212px; overflow: hidden; position: relative; text-decoration: none; width: 318px; }", ".framer-O15fl .framer-8yq1bh { flex: none; height: 100%; left: 0px; overflow: hidden; position: absolute; top: 0px; width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 212
 * @framerIntrinsicWidth 318
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"W0vw9JPdH":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"NRJc423C6":"image","bSr_pnF2L":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerJNrdh0ywG: React.ComponentType<Props> = withCSS(Component, css, "framer-O15fl") as typeof Component;
export default FramerJNrdh0ywG;

FramerJNrdh0ywG.displayName = "Thumbnail";

FramerJNrdh0ywG.defaultProps = {height: 212, width: 318};

addPropertyControls(FramerJNrdh0ywG, {NRJc423C6: {title: "Image", type: ControlType.ResponsiveImage}, bSr_pnF2L: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerJNrdh0ywG, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})